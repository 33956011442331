import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Separator from "../elements/separator/Separator";
import FooterThree from '../common/footer/FooterThree';
import { FiArrowRight } from "react-icons/fi";
import {Link} from "react-router-dom";


const Splash = () => {
    return (
        <>
           <SEO title="Home" />
           <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <div className="react-image mb--20">
                                        <img src="./images/demo/react-badge.png" alt="Doob React" />
                                    </div>
                                    <h1 className="title display-two">
                                        Revolutionizing healthcare  <br />
                                        with <span className="theme-gradient">AI-powered</span>  {" "}
                                        <span className="theme-gradient">detection</span>{" "}of
                                        <br />
                                        TB, Pneumonia and COVID-19.
                                    </h1>
                                    <p className="description">This project can facilitate precise and timely detection of COVID-19, Pneumonia, and TB through the use of advanced deep learning techniques applied to chest X-ray images.</p>
                                    <div className="button-group">
                                        <Link to="/about" className="btn-default btn-large round btn-icon">Project Details <FiArrowRight /></Link>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                <FooterThree />
           </main>

        </>
    )
}

export default Splash;