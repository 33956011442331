import React from 'react';
import { FiActivity } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'Pneumonia',
        description: 'Pneumonia is a serious and potentially life-threatening lung infection that can affect people of all ages, but is particularly dangerous for young children, the elderly, and those with weakened immune systems. It is estimated to be responsible for over 2 million deaths each year, with the majority occurring in low- and middle-income countries. The infection can be caused by bacteria, viruses, or fungi, and can lead to severe respiratory distress, sepsis, and organ failure. Prevention measures such as vaccination, good hygiene practices, and avoiding exposure to pollutants can help reduce the risk of pneumonia. Early diagnosis and treatment with appropriate antibiotics are essential to prevent complications and improve outcomes.'
    },
    {
        icon: <FiActivity />,
        title: 'Covid-19',
        description: 'COVID-19, caused by the SARS-CoV-2 virus, has emerged as a significant threat to public health worldwide. The virus spreads rapidly from person to person, leading to a wide range of symptoms, from mild flu-like symptoms to severe respiratory distress, multi-organ failure, and death. The high mortality rates of COVID-19 have overwhelmed healthcare systems, leading to shortages of medical supplies, hospital beds, and healthcare workers. The pandemic has also led to significant social and economic disruptions, affecting individuals, businesses, and governments globally. Vaccines and preventive measures, such as physical distancing, are essential tools to control the spread of the virus and mitigate the impact of the pandemic.'
    },
    {
        icon: <FiActivity />,
        title: 'Tuberculosis',
        description: 'Tuberculosis (TB) is a highly infectious bacterial disease that primarily affects the lungs but can also affect other parts of the body. It spreads through the air when an infected person coughs or sneezes, and can easily transmit from person to person. TB is one of the top 10 causes of death worldwide. TB is a significant public health threat, as it can be difficult to diagnose and treat, especially in resource-limited settings. The emergence of drug-resistant TB strains has further complicated the situation, making it more challenging to control and eradicate the disease. TB prevention and control efforts require a comprehensive approach, including early detection, prompt treatment, vaccination, and public awareness campaigns.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;