import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceThree from "./ServiceThree";
import ServiceFour from "./ServiceFour";

const Service = () => {
    return (
        <>

            <SEO title="X-Ray Upload - React Business  Template" />
            <Layout>
                <div className="main-content">

                      {/* Start Service Area  */}
                    <div className="rn-service-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        // subtitle = "What we can do for you"
                                        title = "Upload Your X-Ray"
                                        description = "The result could be one of the 4 analysis types below"
                                     />
                                </div>
                            </div>
                            <ServiceFour 
                                serviceStyle = "service__style--1 icon-circle-style with-working-process"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}
                    {/* Start Service Area  */}
                    <div className="rn-service-area copyright-style-one mt--10">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        // subtitle = "What we can do for you"
                                        // title = "Services provide for you."
                                        description = "File should only be in png, jpg or jpeg format"
                                     />
                                </div>
                            </div>
                                    <ServiceThree 
                                serviceStyle = "service__style--2"
                                textAlign = "text-center"
                                    />
                            
                            
                            
                        </div>
                        {/* <div className="button-group mt--10 text-center copyright-style-one ">
                                        <a className="btn-default btn-large round btn-icon" href="/about-us">Upload & Process X-Ray</a>
                                    </div> */}
                    </div>
                    {/* End Service Area  */}



            

                    
                </div>

            </Layout>
            
        </>
    )
}

export default Service;
