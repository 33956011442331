import React from 'react';
// import { AiFillApple, AiFillAndroid } from "react-icons/ai";

const callToActionData = {
    title: "Interested in partnering on this project?",
    subtitle: "Get in touch on +44 7754327291 or netappzone@gmail.com",
    btnText: "Get it on Google Play",
    btnText2: "Get it on App Store",
}

const CalltoActionEight = () => {
    return (
        <div className="rn-callto-action rn-call-to-action style-8 content-wrapper">
            <div className="container">
                <div className="row row--0 align-items-center ">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title">{callToActionData.title}</h2>
                                <h6 className="subtitle">{callToActionData.subtitle}</h6>
                                 {/* <div className="button-group mt--20">
                                        <a className="btn-default btn-icon" target="_blank" href="https://johnoye.com" rel="noreferrer"><i className="icon mr--10"><AiFillApple /></i>{callToActionData.btnText2}</a>
                                        <a className="btn-default btn-icon" target="_blank" href="https://play.google.com/store/apps;?pli=1" rel="noreferrer"><i className="icon mr--10"><AiFillAndroid /></i>{callToActionData.btnText}</a>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionEight;