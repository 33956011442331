// import { useEffect } from "react";
// import { withRouter } from 'react-router-dom';
// const PageScrollTop = (props) => {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     });
//     return props.children;
// };
// export default withRouter(PageScrollTop);

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PageScrollTop = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return props.children;
};

export default PageScrollTop;