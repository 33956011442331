import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li><Link to="/">Home</Link>
            </li>
            
            <li><Link to="/about/">About Project</Link></li>
            <li><Link to="/projects/">Other Projects</Link></li>

            <li><Link to="/contact/">Contact</Link></li>
        </ul>
    )
}
export default Nav;
