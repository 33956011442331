/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useDropzone } from "react-dropzone";
import * as tf from "@tensorflow/tfjs";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import LoadingOverlay from 'react-loading-overlay-ts'


const url = {
  model: "/TFJS/model.json",
};

const urlX = {
  modelX: "/XRAY/model.json",
};


const ServiceList = [
  {
    image: "/images/service/fileUpload.png",
    // title: 'Awarded Design',
    description: "I agree to the privacy policy and terms of use",
  },
];

const ServiceThree = ({ textAlign, serviceStyle }) => {
  const baseStyle = {
    borderWidth: 2,
    borderRadius: "2rem",
    borderColor: "gray",
    borderStyle: "dashed",
    color: "#bdbdbd",
    transition: "border .3s ease-in-out",
    padding: "3rem",
    marginBottom: "2rem",
  };

  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const [model, setModel] = useState();
  const [modelX, setModelX] = useState();
  const [files, setFiles] = useState([]);
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setResult("");
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
    };

//     function refreshPage() {
//   window.location.reload(false);
// }
    

  const loadModel = async (url) => {
    try {
      // For layered model
      const model = await tf.loadLayersModel(url.model);
      setModel(model);
      // console.log("Load model success");
    } catch (err) {
      // console.log(err);
    }
  };

  const loadModelX = async (urlX) => {
    try {
      // For layered model
      const modelX = await tf.loadLayersModel(urlX.modelX);
      setModelX(modelX);
      // console.log("Load model success");
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    tf.ready().then(() => {
      loadModel(url);
      loadModelX(urlX);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],

      "image/jpg": [],
      "image/png": [],
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [
      baseStyle,
      isDragActive,
      activeStyle,
      isDragAccept,
      acceptStyle,
      isDragReject,
      rejectStyle,
    ]
  );

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
    );
    
    const handleUploadImage = async () => {
          if (agree) {
              
      try {
        const file = files[0];

        const classNames = ["covid", "normal", "pneumonia", "tuberculosis"];
        var result = 0;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setLoading(true);
        reader.onload = async () => {
          setLoading(true);
          const img = new Image();
          img.src = reader.result;

          img.onload = async () => {
            const tensor = tf.browser
              .fromPixels(img)
              .resizeBilinear([224, 224])
              .toFloat();
            const offset = tf.scalar(255.0);
            const normalized = tensor.div(offset).expandDims(0);
            const xPredictions = modelX.predict(normalized);
            // const values = Array.from(xPredictions.dataSync());
            var xIndex = tf.argMax(xPredictions, 1).dataSync();
            // console.log(xIndex[0])
            if (xIndex[0] === 1) {
              const predictions = model.predict(normalized);
              var pIndex = tf.argMax(predictions, 1).dataSync();
              result = pIndex;

              //   const token = localStorage.getItem('token');
              //   if(token){
              //     const added = await client.add({path:file.name,content:file},{
              //       wrapWithDirectory: true,
              //     })
              //     const url = `https://ipfs.infura.io/ipfs/${added.cid.toString()}/${file.name}`
              //     const response = await axios.post("https://nakshatra-demo.herokuapp.com/api/reports",
              //     {image:url,result:classNames[result]},
              //     { headers: { "Authorization": `Bearer ${token}` }});
              //     setLoading(false)
              //   }
              setLoading(false);
              setResult(classNames[result]);
            } else {
              toast.error("Error analysing image \nPlease Upload an XRAY", {
                position: toast.POSITION.TOP_CENTER,
              });
              setLoading(false);
              setResult(false);
            }
          };
        };
        setLoading(false);
        setFiles([]);
      } catch (error) {
        setLoading(false);

        toast.error("Kindly add image of type jpg or png", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
          } else {
      toast.error("Kindly accept the terms of use", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };


    return (
      <LoadingOverlay
      active={loading}
      spinner text="Analysing your X-Ray..."
    >
    <div className="container-fluid row--15  pcotube-content">
      {ServiceList.map((val, i) => (
        <div className="col-12 col-md-6 pcotube-wrapper" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="inner">
                <div className="image" {...getRootProps({ style })}>
                  <input {...getInputProps()} />

                  {files && files.length === 0 ? (
                    <>
                      <img src={`${val.image}`} alt="card Images" />
                      <p className="mt-3 my-0 text-center">
                        Click or Drag 'n' Drop your Chest X-Ray here
                      </p>
                    </>
                  ) : (
                    <>
                      <img
                        src={URL.createObjectURL(files[0])}
                        alt="file upload"
                      />
                      <p className="text-success text-blue-500 mt-3">
                        File Added Successfully
                      </p>
                    </>
                  )}
                </div>
                <div className="content">

                  <span class="checkbox-label">
                    <input
                      type="checkbox"
                      name="terms"
                      id="agree"
                      onChange={checkboxHandler}
                    />
                    <label for="agree">
                      <span className="ml-2 text-sm link-hover">
                    I agree to the{" "}
                    <a target="_blank" href="/" rel="noreferrer">
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a target="_blank" href="/" rel="noreferrer">
                      Terms of Use.
                    </a>
                  </span>
                    </label>
                  </span>
                </div>
                      </div>
                      <div className="button-group my-3 mt--10 text-center ">
                          
                    {result && (
                      <div className="uploadImage__result  mt-4 mb-4 d-flex justify-content-center align-items-center flex-column">
                        {result && result === "normal" ? (
                          <p className="bolder">
                            Your chest X-Ray is classified as{" "}
                            <strong className="text-blue-800">
                              {result.toUpperCase()}
                            </strong>
                          </p>
                        ) : (
                          <p>
                            There are potential sign of{" "}
                            <strong className="fw-bolder">
                              {result.toUpperCase()}
                            </strong>{" "}
                            in your X-Ray. Please, go for further analysis
                          </p>
                        )}
                      </div>
                    )}

                           <button
                        className="btn-default btn-small round btn-icon"
                        type="button"
                        id="leftbutton"
                        onClick={handleUploadImage}
                      >
                        Upload & Process X-Ray
                      </button>
                      <ToastContainer />
                                    
                    </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
            </div>
            </LoadingOverlay>
  );
};
export default ServiceThree;
