import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    What does this project try to achieve?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>The primary objective of this project is to develop a web and mobile application that uses advanced AI algorithms to detect COVID-19, pneumonia, and tuberculosis from chest X-ray images. The application is designed to improve the productivity of healthcare professionals, while simultaneously reducing the time and cost for patients. By utilizing this application, users can obtain fast and accurate AI predictions for chest X-ray images, which could be across these possibilities: Normal, Pneumonia, Tuberculosis or COVID-19. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                   Can this application be relied on for diagnosis?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>No. For now, this application is to be used for investigational purpose only and NOT for diagnosis</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Is this project running live already?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Yes, this project is live in production. I am already talking to individuals and organization interested in taking this project to the next level.</Card.Body>
                </Accordion.Collapse>
            </Card>


              <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Are you still open to collaboration on this project or other projects?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Yes, I can be reached on +44 7754327291</Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

