import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { AiFillIeCircle  } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

const teamData = [
    // {
    //     image: 'team-01',
    //     name: 'Sr Janen Sara',
    //     designation: 'Sr Product Designer',
    //     location: 'CO Miego, AD, USA', 
    //     description: 'Yes, I am a product designer. I have a passion for product design.',
    //     socialNetwork: [
    //         {
    //             icon: <FiFacebook />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FiTwitter />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FiInstagram />,
    //             url: '#'
    //         },
    //     ]
       
    // },
    {
        image: 'john',
        name: 'Johnson Oyeniyi',
        designation: 'Senior Developer',
        location: 'United Kingdom', 
        description: 'Yes, I am a developer with more than 10 years of experience. I have a passion for innovative designs.',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/johnsonoy/'
            },
            {
                icon: <AiFillIeCircle />,
                url: 'https://johnoye.com/'
            },
        ]
      
    },
    // {
    //     image: 'team-03',
    //     name: 'Afanan Sifa',
    //     designation: 'Accounts Manager',
    //     location: 'Poland', 
    //     description: 'Yes, I am a product designer. I have a passion for product design.',
    //     socialNetwork: [
    //         {
    //             icon: <FiFacebook />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FiTwitter />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FiInstagram />,
    //             url: '#'
    //         },
    //     ]
        
    // },
]


const TeamOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`rn-team ${teamStyle}`}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`/images/team/${data.image}.jpg`} alt="dev pic" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <span className="team-form">
                                        <img src="/images/team/location.svg" alt="dev pic" />
                                        <span className="location">{data.location}</span>
                                    </span>
                                    <p className="description">{data.description}</p>

                                    <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a target="_blank" href={`${social.url}`} rel="noreferrer">{social.icon}</a></li>
                                        )}
                                    </ul>
                                </figcaption>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TeamOne;
