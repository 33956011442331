import React from 'react'
import SEO from "../common/SEO";
// import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import TeamOne from '../elements/team/TeamOne';
import FooterThree from '../common/footer/FooterThree';
import AboutThree from '../elements/about/AboutThree';

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/healthy.png)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    {/* <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">About PCoTube</span></h3>
                                    </div> */}
                                    <h1 className="title display-one">A healthy nation is a <br /> wealthy nation.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">AI - Transforming Healthcare, One Innovation at a Time.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">AI has the potential to transform healthcare by improving disease detection and diagnosis, predicting patient outcomes, developing personalized treatment plans, and enhancing operational efficiency. By leveraging AI, the healthcare industry can deliver more accurate, cost-effective, and timely care to patients worldwide.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}



                {/* Start Brand Area  */}
                {/* <div className="rwt-brand-area pb--60 pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area  */}


                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "About PCoTube"
                                    title = "Pneumonia | Covid-19 | Tuberculosis"
                                    description = "The global impact of COVID-19, Pneumonia, and Tuberculosis has been devastating, leading to millions of deaths and significant economic and social disruptions. These diseases have highlighted the need for robust public health measures, improved healthcare infrastructure, and advanced technologies to detect and treat infectious diseases effectively."
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <div className="rwt-timeline-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "The Model"
                                    title = "Model Development Processes"
                                    description = ""
                                />
                                <TimelineTwo classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div>
                <Separator />
                <AboutThree />

                <Separator />

                {/* <AboutFour image="./images/about/contact-image.jpg" /> */}
                
                {/* Start Elements Area  */}
                {/* <div className="rwt-counterup-area pb--100">
                    <div className="container mt_dec--30">
                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                    </div>
                </div> */}
                {/* End Elements Area  */}

                {/* <Separator /> */}

                {/* Start Elements Area  */}
                <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "The Developer"
                                    title = "About the Developer"
                                    description = ""
                                />
                                
                            </div>
                        </div>
                         <TeamOne column="col-lg-12 col-md-12 col-12 mt--30" teamStyle="team-style-default" />
                    </div>
                </div>
                {/* End Elements Area  */}
                 {/* <CalltoActionEight /> */}






                <FooterThree />
            </main>
        </>
    )
}

export default AboutUs;
